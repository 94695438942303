// src/components/ChatWidget/ChatWidget.js
import React, { useState } from 'react';
import { BsFillChatFill } from 'react-icons/bs';
import ModalWindow from './ModalWindow';
import { styles } from './styles';

const ChatWidget = () => {
  const [hovered, setHovered] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <div
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={toggleModal}
        style={{
          ...styles.chatWidget,
          border: hovered ? '1px solid black' : '',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <BsFillChatFill size={20} color="white" />
          <span style={styles.chatWidgetText}>Need Help?</span>
        </div>
      </div>
      <ModalWindow isOpen={isOpen} toggleModal={toggleModal} />
    </div>
  );
};

export default ChatWidget;
