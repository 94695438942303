import React, { useState } from 'react';
import { styles } from '../styles';
import { FaPaperPlane, FaTimes } from 'react-icons/fa';

const predefinedQuestions = [
  'Platform Features',
  'Pricing',
  'Technical Issues',
  'Billing or Account Issues',
  'Just Browsing',
  'Other'
];

const botResponses = {
  'Platform Features': 'Our platform offers a variety of features including X, Y, and Z.',
  'Pricing': 'Our pricing plans start at $X per month. For more details, visit our pricing page.',
  'Technical Issues': 'Please describe the technical issue you are facing in detail so we can assist you better.',
  'Billing or Account Issues': 'For billing or account issues, please contact our support team at support@example.com.',
  'Just Browsing': 'Feel free to browse through our platform. Let us know if you have any questions.',
  'Other': 'Please provide more details about your query.'
};

const ModalWindow = ({ isOpen, toggleModal }) => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');

  const handleSendMessage = () => {
    if (input.trim()) {
      setMessages([...messages, { sender: 'user', text: input }]);
      setBotResponse(input);
      setInput('');
    }
  };

  const handlePredefinedQuestion = (question) => {
    setMessages([...messages, { sender: 'user', text: question }, { sender: 'bot', text: botResponses[question] }]);
  };

  const setBotResponse = (userMessage) => {
    // You can add custom logic here to generate a bot response for custom messages
    const defaultResponse = 'Thank you for your message. We will get back to you shortly.';
    setMessages((prevMessages) => [...prevMessages, { sender: 'bot', text: defaultResponse }]);
  };

  return (
    <div style={{ ...styles.modal.container, display: isOpen ? 'block' : 'none' }}>
      <div style={styles.chatHeader}>
        <FaTimes onClick={toggleModal} style={styles.closeIcon} />
      </div>
      <div style={styles.chatBody}>
        <div style={styles.predefinedQuestions}>
          {predefinedQuestions.map((question, index) => (
            <button
              key={index}
              style={styles.questionButton}
              onClick={() => handlePredefinedQuestion(question)}
            >
              {question}
            </button>
          ))}
        </div>
        {messages.map((msg, index) => (
          <div key={index} style={msg.sender === 'user' ? styles.userMessage : styles.botMessage}>
            {msg.text}
          </div>
        ))}
      </div>
      <div style={styles.chatFooter}>
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Type a message..."
          style={styles.input}
        />
        <FaPaperPlane onClick={handleSendMessage} style={styles.sendIcon} />
      </div>
    </div>
  );
};

export default ModalWindow;
