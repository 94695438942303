import React , { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const Main = () => {
  const { userId, token } = useParams(); // Extrage userId și token din URL
  console.log("UserID:", userId, "Token:", token); // Debugging

  useEffect(() => {
    console.log("Params updated:", userId, token);
  }, [userId, token]); // Se execută la fiecare schimbare a parametrilor
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    try {
      // reset password
      const response = await fetch(`https://auth.api.staging.gethealr.app/api/password-reset/${userId}/${token}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ password }),
      });

      if (!response.ok) {
        throw new Error("Invalid or expired token");
      }

      setSuccess(true);
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div className="page_wrapper">
    <div className="full_bg">
        <div className="container">
            <h2>Reset Password</h2>
            {error && <p className="error">{error}</p>}
            {success ? (
                <p className="success">Password reset successfully!</p>
            ) : (
                <form onSubmit={handleSubmit}>
                <label>New Password:</label>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />

                <label>Confirm Password:</label>
                <input
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                />

                <button type="submit">Reset Password</button>
                </form>
            )}
         </div>
      </div>
    </div>
  );

};

export default Main;