// src/components/ChatWidget/styles.js
export const styles = {
  chatWidget: {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    backgroundColor: 'var(--bg-purple)',
    padding: '7px 18px',
    borderRadius: '10px',
    cursor: 'pointer',
  },
  chatWidgetText: {
    color: 'var(--text-white)',
    fontSize: '15px',
    marginLeft: '5px',
    curspr: 'pointer',
  },
  modal: {
    container: {
      position: 'fixed',
      bottom: '80px',
      right: '20px',
      width: '450px', // Increased from 300px
      maxWidth: 'calc(100% - 40px)',
      backgroundColor: 'var(--bg-white)',
      borderRadius: '10px',
      boxShadow: '0 3px 10px rgba(0, 0, 0, 0.2)',
      zIndex: 10000,
    },
  },
  chatHeader: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '10px',
    background: 'var(--dark-purple)',
    color: 'var(--text-white)',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
  },
  closeIcon: {
    cursor: 'pointer',
  },
  chatBody: {
    padding: '10px',
    maxHeight: '450px', // Adjusted to fit the new modal size
    overflowY: 'auto',
    backgroundColor: 'var(--light-bg)',
  },
  userMessage: {
    backgroundColor: 'var(--light-purple)',
    padding: '10px',
    borderRadius: '10px',
    margin: '5px 0',
    textAlign: 'right',
    alignSelf: 'flex-end',
  },
  botMessage: {
    backgroundColor: 'var(--slider-dots-color)',
    padding: '10px',
    borderRadius: '10px',
    margin: '5px 0',
    textAlign: 'left',
    alignSelf: 'flex-start',
  },
  predefinedQuestions: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    justifyContent: 'center', // Center the buttons
    marginTop: '10px',
  },
  questionButton: {
    padding: '10px',
    border: '1px solid var(--slider-dots-color)',
    borderRadius: '5px',
    cursor: 'pointer',
    backgroundColor: 'var(--light-purple)',
    color: 'var(--body-text-purple)',
    margin: '5px 0',
    width: 'calc(50% - 10px)', // Each button takes half width minus gap
    textAlign: 'center', // Center the text inside the buttons
  },
  chatFooter: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    borderTop: '1px solid var(--slider-dots-color)',
    backgroundColor: 'var(--bg-white)',
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
  },
  input: {
    flex: '1',
    padding: '10px',
    borderRadius: '5px',
    border: '1px solid var(--slider-dots-color)',
    marginRight: '10px',
  },
  sendIcon: {
    cursor: 'pointer',
    fontSize: '20px',
    color: 'var(--purple)',
  },
};
